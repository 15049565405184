import React from "react"
import EditOrgUser from "../AdminUser/EditOrgUser"

import { OrgStudentUser } from "../../type/User"

interface PropsType {
  user: OrgStudentUser
  isCard?: boolean
}

const OrgUserReviewCard: React.FC<PropsType> = ({ user, isCard }) => {
  return (
    <div className="h-full flex flex-col">
      {/*Content*/}
      <div className="grow  h-full overflow-hidden">
        <div className="px-2">
          <EditOrgUser data={user} />
        </div>
      </div>
    </div>
  )
}
export default OrgUserReviewCard
