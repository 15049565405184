import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getAllArticle,
  getAllArticleCondition,
} from "../helperFunction/articleDBHelper"
import { Article, ARTICLE_LEVEL } from "../type/Article"

export const getNewsContent = createAsyncThunk(
  "/news/content",
  async (data: getAllArticleCondition) => {
    const response = await getAllArticle({
      startAfter: data.startAfter,
      endAt: data.endAt,
      category: data.category,
    })
    return response
  }
)

const onChangeWordLevel = (selectedItems: string[], updateItem: string) => {
  // Create a copy of the original array
  const updatedList = [...selectedItems]

  // Check if the updateItem already exists in the array
  const index = updatedList.indexOf(updateItem)
  if (index !== -1) {
    // If it exists, remove it from the array
    updatedList.splice(index, 1)
  } else {
    // If it doesn't exist, add it to the array
    updatedList.push(updateItem)
  }

  return updatedList
}

export interface NewsManagerState {
  isLoading: boolean
  results: Article[]
  hasMore: boolean
  // for admin picking up article or user reading the article
  selectedArticle: Article | object
  selectedWordLevel: string[]
  selectedArticleLevel: ARTICLE_LEVEL
}

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    isLoading: false,
    results: [],
    hasMore: false,
    // for admin picking up article or user reading the article
    selectedArticle: {},
    selectedWordLevel: [],
    selectedArticleLevel: ARTICLE_LEVEL.EASY,
  } as NewsManagerState,
  reducers: {
    selectArticle: (state, action) => {
      state.selectedArticle = action.payload
    },
    setArticleLevel: (state, action) => {
      state.selectedArticleLevel = action.payload
    },
    onSelectWordLevel: (state, action) => {
      state.selectedWordLevel = onChangeWordLevel(
        state.selectedWordLevel,
        action.payload
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsContent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getNewsContent.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.results = action.payload.data
          state.hasMore = action.payload.hasMore
        }

        state.isLoading = false
      })
      .addCase(getNewsContent.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { selectArticle, setArticleLevel, onSelectWordLevel } =
  newsSlice.actions

export default newsSlice.reducer
