import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitle } from "../../redux/headerSlice"
import { isAuth, isEmpty } from "../../utils/utiltyHelper"
import ListQuizComponent from "../../page-components/ListQuizComponent"
import { t } from "i18next"
import { Quiz } from "../../type/Quiz"
import {
  getUserAssignedQuiz,
  getUserAssignedQuizCondition,
} from "../../helperFunction/quizDBHelper"

export enum ListQuizViewItem {
  TEACHER = "teacher",
  USER = "user",
}

function InternalPage() {
  const { search, pathname } = useLocation()

  function useQuery() {
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()

  const params = useParams()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const { isLoading, profile } = useSelector((state: any) => state.user)

  const [viewItem, setViewItem] = useState<ListQuizViewItem>(
    ListQuizViewItem.TEACHER
  )

  const [quizzes, setQuizzes] = useState<Quiz[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [condition, setCondition] = useState<getUserAssignedQuizCondition>({
    startAfter: "",
    endAt: "",
    type: ListQuizViewItem.TEACHER,
    uid: auth.uid,
  })

  useEffect(() => {
    let queryStr: ListQuizViewItem = query.get(
      "type"
    ) as unknown as ListQuizViewItem
    if (queryStr) {
      setViewItem(queryStr)
      setCondition({
        ...condition,
        type: queryStr,
      })
    }
  }, [query])

  useEffect(() => {
    dispatch(setPageTitle({ title: t("QuizPage.title") }))
  }, [])

  useEffect(() => {
    if (!isLoading && isAuth(auth) && !isEmpty(profile)) {
      setIsLoaded(false)

      getUserAssignedQuiz(auth.orgID, profile.orgGroups, {
        ...condition,
        uid: auth.uid,
      }).then((result) => {
        if (result.success) {
          setQuizzes(result.data)
          setIsLoaded(true)
          setHasMore(result.hasMore)
        } else {
          setIsLoaded(true)
          setHasMore(false)
        }
      })
    }
  }, [isLoading, auth, profile, condition])

  return (
    <ListQuizComponent
      quizzes={quizzes}
      viewType={viewItem}
      isLoaded={isLoaded}
      hasMore={hasMore}
    />
  )
}

export default InternalPage
