import {
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import firebaseApp from "../config/firebase"
import { Article, ArticleCategory } from "../type/Article"
import { DateObj } from "../type/SystemType"

const db = getFirestore(firebaseApp)

const getArticle = (
  id: string
): Promise<{ success: true; data: Article } | { success: false }> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Article", id))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Article,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 *
 * @param
 * @param condition
 */
export interface getAllArticleCondition {
  startAfter?: "" | DateObj | Date
  endAt?: "" | DateObj | Date
  category?: string | null
}

const getAllArticle = (
  condition: getAllArticleCondition
): Promise<
  | {
      success: true
      data: Article[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Article")

    dbRef = query(dbRef, where("isPublish", "==", true))

    if (condition.startAfter) {
      dbRef = query(dbRef, where("createDate", ">=", condition.startAfter))
    }

    if (condition.endAt) {
      dbRef = query(dbRef, where("createDate", "<", condition.endAt))
    } else {
      //default the createDate cannot be future
      const now = new Date()
      dbRef = query(dbRef, where("createDate", "<", now))
    }

    if (condition.category) {
      dbRef = query(dbRef, where("category", "==", condition.category))
    }

    await getDocs(query(dbRef, orderBy("createDate", "desc"), limit(12)))
      .then((docs: any) => {
        if (!docs.empty) {
          let allArticles: Article[] = []
          docs.forEach((doc: any) => {
            allArticles.push({
              id: doc.id,
              ...doc.data(),
            } as Article)
          })

          return resolve({
            success: true,
            data: allArticles,
            hasMore: allArticles.length === 12,
          })
        }
        return resolve({
          success: true,
          data: [],
          hasMore: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export { getArticle, getAllArticle }
