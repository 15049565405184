import React from "react"
import Alert from "../Alert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../enum/ALERT_SYSYEM"
import classNames from "classnames"

interface PropsType {
  data: any[]
  itemsPerPage: number
  currentPage: number
  onPageChange: (index: number) => void
  component: React.ComponentType<any>
  size?: PaginationCardSize
}

export enum PaginationCardSize {
  LG,
  XL,
}

const PaginationCard: React.FC<PropsType> = ({
  data,
  itemsPerPage,
  currentPage,
  onPageChange,
  component: Component,
  size,
}) => {
  return (
    <div className="h-full relative">
      <div className="h-full overflow-y-scroll pb-20">
        <ul
          className={classNames("menu bg-none gap-2 py-1 rounded-box", {
            "grid grid-cols-1 lg:grid-cols-2 gap-2":
              size === PaginationCardSize.LG,
            "grid grid-cols-2 lg:grid-cols-3 gap-2":
              size === PaginationCardSize.XL,
          })}>
          {data.map((item, index) => (
            <li key={index}>
              <Component data={item} />
            </li>
          ))}
        </ul>

        {data.length === 0 && (
          <div className="my-4">
            <Alert
              title={"CustomTable.isLastRecord"}
              alertType={ALERT_TYPE.INFO}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
            />
          </div>
        )}
      </div>

      <div className="absolute bottom-0 px-2 h-8 bg-white  w-full grid grid-cols-2 gap-4">
        <button
          className="btn btn-xs btn-primary"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 0}>
          Previous
        </button>
        <button
          className="btn btn-xs btn-primary"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={
            (currentPage + 1) * itemsPerPage < data.length ||
            data.length < itemsPerPage
          }>
          Next
        </button>
      </div>
    </div>
  )
}

export default PaginationCard
