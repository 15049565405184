import { Capacitor } from "@capacitor/core"
import { getAuth, IdTokenResult } from "firebase/auth"
import firebaseApp from "../config/firebase"
import firebase from "firebase/compat"
import { Timestamp } from "firebase/firestore"
import { DateObj } from "../type/SystemType"

const isLocalhost = () => {
  try {
    return (
      //if not look4kol domain
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("192.168.")
    )
  } catch (e) {
    return false
  }
}

export function getAPIPath(path: string) {
  const isMobileDevice =
    Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios"

  const testUrl = "http://127.0.0.1:5001/your-en-memory/asia-east2" + path
  const deploymentUrl =
    "https://asia-east2-your-en-memory.cloudfunctions.net" + path

  const localUrl = isLocalhost() ? testUrl : deploymentUrl
  return isMobileDevice ? deploymentUrl : localUrl
}

export { isLocalhost }

export function timeStampToDisplayTimeString(ts: any) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const date = timeConverter(ts)

  const min =
    date.getMinutes() < 10
      ? "0" + date.getMinutes().toString()
      : date.getMinutes()

  return (
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    ", " +
    date.getFullYear() +
    " | " +
    (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) +
    ":" +
    (date.getMinutes() === 0 ? "00" : min) +
    (date.getHours() > 12 ? " PM" : " AM")
  )
}

export function dateToDisplayTimeString(date: Date) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const min =
    date.getMinutes() < 10
      ? "0" + date.getMinutes().toString()
      : date.getMinutes()

  return (
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    ", " +
    date.getFullYear()
  )
}

export const getUserLang = (): "hk" | "en" => {
  return localStorage.getItem("i18n-lang") === "en" ? "en" : "hk"
}

export function isAuth(authObj: any) {
  return (
    authObj !== null &&
    typeof authObj !== "undefined" &&
    authObj.uid !== "" &&
    authObj.uid
  )
}

export const getWeekDay = () => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  let d = new Date()
  return days[d.getDay()]
}

export const objectDateToString = (obj: object) => {
  if (!obj || typeof obj !== "object") {
    // If the input is not an object, return the value as-is
    return obj
  }

  // Create a new object to store the converted values
  const result: any = {}

  // Iterate over the object's keys
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value: any = obj[key]

      if (value instanceof Date) {
        // If the value is a Date object, convert it to a string representation
        result[key] = value.toISOString()
      } else if (Array.isArray(value)) {
        // If the value is an array, recursively convert each element
        result[key] = value.map((item) => objectDateToString(item))
      } else if (typeof value === "object" && value !== null) {
        // If the value is an object, recursively convert its properties
        result[key] = objectDateToString(value)
      } else {
        // Otherwise, keep the original value
        result[key] = value
      }
    }
  }

  return result
}

export const returnIdTokenResult = () => {
  return new Promise((resolve, reject) => {
    const auth: any = getAuth(firebaseApp)
    if (auth.currentUser) {
      auth.currentUser
        .getIdTokenResult()
        .then((idTokenResult: IdTokenResult) => {
          return resolve({
            success: true,
            email: auth.currentUser.email,
            uid: auth.currentUser.uid,
            photoURL: auth.currentUser.photoURL,
            displayName: auth.currentUser.displayName,
            token: idTokenResult.token,
            role: idTokenResult.claims.role,
            expirationTime: idTokenResult.expirationTime,
            custID: idTokenResult.claims.custID
              ? idTokenResult.claims.custID
              : null,
          })
        })
    }
  })
}

export const objectFieldsToDateType = (obj: any): any => {
  if (Array.isArray(obj)) {
    // If the input is an array, process each element
    return obj.map((item: any) => objectFieldsToDateType(item))
  } else if (obj instanceof Object) {
    if (obj.seconds !== undefined && obj.nanoseconds !== undefined) {
      // Check if the object is a timestamp
      return new Date(obj.seconds * 1000 + obj.nanoseconds / 1000000)
    } else if (obj instanceof Date) {
      return obj
    }

    // Recursively process other properties if it's an object
    const newObj: any = {}
    for (const key in obj) {
      newObj[key] = objectFieldsToDateType(obj[key])
    }
    return newObj
  }
  return obj
}

export function logout() {
  const auth = getAuth(firebaseApp)
  auth
    .signOut()
    .then(function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localStorage.setItem("vocabsphere-auth", null)
      localStorage.setItem("vocabsphere-require-logout", String(false))
    })
    .catch(function (error) {
      // An error happened
    })
}

export function isStringHaveSpacesAndNewLinesOnly(str: string) {
  let regex = /^[ \r\n]*$/
  return regex.test(str)
}

export const getTimeFromAnyDateType = (date: Date | DateObj | Timestamp) => {
  return "seconds" in date
    ? date.seconds
    : Math.round(new Date(date).getTime() / 1000)
}

export const isEmpty = (data: object) => {
  try {
    return Object.keys(data).length === 0 && data.constructor === Object
  } catch (e) {
    return true
  }
}

export const timeConverter = (obj: any): Date => {
  try {
    let date

    if (typeof obj === "string" || typeof obj === "number") {
      date = new Date(obj)
    }

    if (obj.hasOwnProperty("seconds")) {
      date = new Date(obj.seconds * 1000)
    }

    if (date instanceof Date && !isNaN(date.getTime())) {
      return date
    }

    return new Date(obj)
  } catch (e) {
    console.log(e)
    return new Date()
  }
}

export const updateSearchParams = (
  searchQuery: any,
  key: string,
  value: string
) => {
  const existingSearchParams = new URLSearchParams(searchQuery)
  existingSearchParams.set(key, value)

  return existingSearchParams.toString()
}

export enum IMGUR_THUMBNAIL_TYPE {
  s = "s",
  b = "b",
  t = "t",
  m = "m",
  l = "l",
  h = "h",
}

export const returnThumbnailUrl = (
  url: string | undefined,
  type?: IMGUR_THUMBNAIL_TYPE
): string => {
  // doc: https://api.imgur.com/models/image
  try {
    if (typeof url === "string" && url.includes("i.imgur.com")) {
      return url.replace(
        "." + url.split(".")[url.split.length + 1],
        `${type ? type : "m"}.` + url.split(".")[url.split.length + 1]
      )
    }
    return typeof url === "string" ? url : ""
  } catch (e) {
    return ""
  }
}
